<template>
  <v-stepper v-model="e1">
    <v-stepper-header>
      <v-stepper-step :complete="e1 > 1" step="1">
        ร่าง
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 2" step="2">
        ชำระเงิน
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 3" step="3">
        การขนส่ง
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="4">
        เสร็จสิ้น
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1" class="reset-padding-top">
        <v-card class="mb-12" color="grey lighten-1">
          <Step1 />
        </v-card>

        <v-card-actions class="justify-end">
          <v-btn color="primary" @click="e1 = 2">
            Continue
          </v-btn>
        </v-card-actions>
      </v-stepper-content>

      <v-stepper-content step="2" class="reset-padding-top">
        <v-card class="mb-12" color="grey lighten-1">
          <Step2 />
        </v-card>

        <v-card-actions class="justify-end">
          <v-btn color="primary" @click="e1 = 3">
            Continue
          </v-btn>
        </v-card-actions>
      </v-stepper-content>

      <v-stepper-content step="3" class="reset-padding-top">
        <v-card class="mb-12" color="grey lighten-1">
          <Step3 />
        </v-card>

        <v-card-actions class="justify-end">
          <v-btn color="primary" @click="e1 = 4">
            Continue
          </v-btn>
        </v-card-actions>
      </v-stepper-content>

      <v-stepper-content step="4" class="reset-padding-top">
        <v-card class="mb-12" color="grey lighten-1">
          <Success />
        </v-card>

        <v-card-actions class="justify-end">
          <v-btn color="primary" @click="e1 = 1">
            Continue
          </v-btn>
        </v-card-actions>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import Step1 from './step-transit/order/StepOrder.vue'
import Step2 from './step-transit/order/StepPayment.vue'
import Step3 from './step-transit/order/StepDelivery.vue'
import Success from './step-transit/order/Success.vue'

export default {
  components: {
    Step1,
    Step2,
    Step3,
    Success,
  },
  data() {
    return {
      e1: 1,
    }
  },
}
</script>

<style scoped>
.reset-padding-top {
  padding-top: 0 !important;
}
</style>
